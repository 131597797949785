import { Packet } from "../Packet";
export class S44ManufacturingCost extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 44;
    }
}
(function (S44ManufacturingCost) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 0] = "GET_ALL";
        Action[Action["CREATE"] = 1] = "CREATE";
        Action[Action["GET_ONE_RICH"] = 2] = "GET_ONE_RICH";
        Action[Action["ADD_PROCESS_REFER"] = 3] = "ADD_PROCESS_REFER";
        Action[Action["GET_PROCESS_REFER_RICH"] = 4] = "GET_PROCESS_REFER_RICH";
        Action[Action["SET_PROCESS_REFER_ADJUST"] = 5] = "SET_PROCESS_REFER_ADJUST";
        Action[Action["GET_PROCESS_REFER_MATCH"] = 6] = "GET_PROCESS_REFER_MATCH";
        Action[Action["TEST_PROCESS_REFER_ADJUST"] = 7] = "TEST_PROCESS_REFER_ADJUST";
        Action[Action["RECALCULATE_COST_AGGREGATING_GROUP"] = 8] = "RECALCULATE_COST_AGGREGATING_GROUP";
        Action[Action["GET_PROCESS_REFER_ADJUSTMENT"] = 9] = "GET_PROCESS_REFER_ADJUSTMENT";
        Action[Action["DELETE_PROCESS_REFER_ADJUSTMENT"] = 10] = "DELETE_PROCESS_REFER_ADJUSTMENT";
        Action[Action["UPDATE_PROCESS_REFER_ADJUSTMENT"] = 11] = "UPDATE_PROCESS_REFER_ADJUSTMENT";
        Action[Action["MANUFACTURING_COST_SET_PARAM"] = 12] = "MANUFACTURING_COST_SET_PARAM";
        Action[Action["CREATE_NEW_COST_AGGREGATING_GROUP"] = 13] = "CREATE_NEW_COST_AGGREGATING_GROUP";
        Action[Action["CLOSE_GROUP"] = 14] = "CLOSE_GROUP";
        Action[Action["EDIT_GROUP"] = 15] = "EDIT_GROUP";
        Action[Action["DELETE_GROUP"] = 16] = "DELETE_GROUP";
        Action[Action["RECALCULATE_MANUFACTURING_COST_FIXED_EVENT_COMPONENT"] = 17] = "RECALCULATE_MANUFACTURING_COST_FIXED_EVENT_COMPONENT";
    })(Action = S44ManufacturingCost.Action || (S44ManufacturingCost.Action = {}));
    ;
})(S44ManufacturingCost || (S44ManufacturingCost = {}));
