import { Packet } from "../Packet";
export class S32SupplierComponent extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 32;
    }
}
(function (S32SupplierComponent) {
    let Action;
    (function (Action) {
        Action[Action["GET_DEMAND"] = 1] = "GET_DEMAND";
        Action[Action["GENERATE_ORDER"] = 2] = "GENERATE_ORDER";
        Action[Action["GET_ALL_ORDERS"] = 3] = "GET_ALL_ORDERS";
        Action[Action["GET_ALL_BULK_ORDERS"] = 4] = "GET_ALL_BULK_ORDERS";
        Action[Action["GET_BULK_ORDER_RICH"] = 5] = "GET_BULK_ORDER_RICH";
        Action[Action["GET_ORDER_RICH_IN_BULK"] = 6] = "GET_ORDER_RICH_IN_BULK";
        Action[Action["GET_BULK_ORDER_WITH_ORDERS_RICH"] = 7] = "GET_BULK_ORDER_WITH_ORDERS_RICH";
        Action[Action["SUBMIT_RECEPTION"] = 8] = "SUBMIT_RECEPTION";
        Action[Action["GET_ALL_RECEPTIONS"] = 9] = "GET_ALL_RECEPTIONS";
        Action[Action["GET_RECEPTION_PUT"] = 10] = "GET_RECEPTION_PUT";
        Action[Action["GET_RECEPTION_RICH"] = 11] = "GET_RECEPTION_RICH";
        Action[Action["GET_ORDER_RICH"] = 12] = "GET_ORDER_RICH";
        Action[Action["RECEPTION_FIX"] = 13] = "RECEPTION_FIX";
        Action[Action["MERGE_BULK_ORDERS"] = 14] = "MERGE_BULK_ORDERS";
        Action[Action["REVERT_MERGE"] = 15] = "REVERT_MERGE";
        Action[Action["FINALIZE_ORDER"] = 16] = "FINALIZE_ORDER";
        Action[Action["GET_ALL_WAREHOUSE_COMPONENT_SUPPLIERS"] = 17] = "GET_ALL_WAREHOUSE_COMPONENT_SUPPLIERS";
    })(Action = S32SupplierComponent.Action || (S32SupplierComponent.Action = {}));
    ;
})(S32SupplierComponent || (S32SupplierComponent = {}));
