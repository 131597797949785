<script setup lang="ts">
import type { ShowModalFunc } from "@/components/ModalsManager.vue";
import { type MessageSchema } from "@/locales/locales";
import type { ShowToast } from '@/main';
import * as Sentry from "@sentry/vue";
import { inject, onMounted, ref, type Ref } from 'vue';
import { useI18n } from "vue-i18n";
import { EmpireConnection } from 'websocketclientapi';

const { t } = useI18n<{ message: MessageSchema }, string>();
const props = defineProps({
  properties: {
    type: Object,
    required: true
  }
});
const eventId = props.properties.eventId;
const conn = inject('conn') as EmpireConnection;
const showToast = inject('showToast') as ShowToast;
const showModal = inject('showModalFunc') as Ref<ShowModalFunc>;
const userData = inject('userData') as Ref<any>;
const emit = defineEmits(['hidden'])
const myref = ref();

const name = ref('');
const description = ref('');
if (userData.value) {
  name.value = userData.value.fullName;
}

onMounted(async () => {
  myref.value?.show();
});

const reportError = () => {
  const logged = userData.value ? `Logged in as ${userData.value.fullName}` : 'Not logged in';
  console.log(Sentry.captureFeedback({
    name: name.value + ' - ' + logged,
    message: description.value,
    associatedEventId: eventId
  }, {
    includeReplay: true
  }));
  showToast('Zgłoszenie zostało wysłane', 'success', 'Sukces');
  myref.value?.hide();
};
</script>
<template>
  <b-modal ref="myref" @hidden="emit('hidden')" :title="`Zgłaszanie błędu`" size="md">
    <b-form-floating-label label="Imię i nazwisko" class="mb-3">
      <b-form-input trim placeholder="" v-model="name" />
    </b-form-floating-label>
    <b-form-floating-label label="Opisz błąd" class="mb-3">
      <b-form-textarea placeholder="Opisz błąd" v-model="description" style="height: 100px" />
    </b-form-floating-label>
    <template #footer>
      <b-button variant="secondary" @click="myref.hide()">{{ t('actions.close') }}</b-button>
      <b-button variant="primary" @click="reportError()">Zgłoś</b-button>
    </template>
  </b-modal>
</template>