<script setup lang="ts">
import moneySound from '@/assets/the-magical-surprise-141291.mp3';
import { inject, onMounted, type Ref } from 'vue';
import { RouterView } from 'vue-router';
import type { EmpireConnection } from 'websocketclientapi';
import Theme from './components/Theme.vue';
import EndpointEditor from './views/auth/EndpointEditor.vue';
import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community'; 
import { AllEnterpriseModule } from "ag-grid-enterprise";

ModuleRegistry.registerModules([AllCommunityModule, AllEnterpriseModule]);

const conn = inject('conn') as EmpireConnection;
const loading = inject('loading') as Ref<boolean>;
const audio = new Audio(moneySound);

onMounted(() => {
  conn.addListener("NewOrderNotification", () => {
    try {
      audio.play().catch(() => {
        console.error('Failed to play audio');
      });
    } catch (e: any) {
      console.error(e);
    }
  });
});
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.loader {
  background-color: var(--bs-body-bg);
  z-index: 10;
}
</style>
<template>
  <div class="h-100 w-100 d-flex align-items-center justify-content-center loader flex-column" v-if="loading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <EndpointEditor class="mt-5" />
  </div>
  <!-- v-else is needed bcuz WebSocket need to be connected -->
  <RouterView v-slot="{ Component }" v-else>
    <transition name="fade" mode="out-in" appear>
      <component :is="Component" />
    </transition>
  </RouterView>
  <div class="toast-container position-fixed p-3 top-0 end-0" id="toast-container">
  </div>
  <Theme></Theme>
</template>
