<script setup lang="ts">
import { type MessageSchema } from "@/locales/locales";
import EndpointEditor from '@/views/auth/EndpointEditor.vue';
import { Collapse } from 'bootstrap';
import { inject, onMounted, onUpdated, reactive, watch, type Ref } from 'vue';
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from 'vue-router';

const userData = inject('userData') as Ref<any>;
const { t } = useI18n<{ message: MessageSchema }, string>();

// allow only one collapse to be open at a time
let lastCollapse: string | undefined = undefined;
// collapse from current router
let currentCollapse: string | undefined = undefined;
const collapseMap: any = {};
const updateCollapse = () => {
  const currentPath = useRoute().path;
  currentCollapse = undefined;
  for (let item of menuList) {
    for (let item2 of item.children) {
      if (item2.link === currentPath) {
        return;
      }
      for (let item3 of item2.children) {
        if (item3.link === currentPath) {
          currentCollapse = item3.parent!.id;

          // can be null if main page is loading and user is not logged
          collapseMap[currentCollapse]?.show();

          for (let d in collapseMap) {
            if (d !== lastCollapse && currentCollapse !== d) {
              collapseMap[d].hide();
            }
          }
          return;
        }
      }
    }
  }
};

const reloadCollapses = () => {
  const collapseElementList = document.querySelectorAll('.collapse');
  for (let c of collapseElementList) {
    if (collapseMap[c.id] !== undefined) {
      continue;
    }
    collapseMap[c.id] = new Collapse(c, {
      toggle: false
    });
    c.addEventListener('show.bs.collapse', () => {
      lastCollapse = c.id;
      for (let d in collapseMap) {
        if (d !== c.id && currentCollapse !== d) {
          collapseMap[d].hide();
        }
      }
    });
    c.addEventListener('shown.bs.collapse', () => {
      if (lastCollapse !== c.id && currentCollapse !== c.id) {
        collapseMap[c.id].hide();
      }
    });
  }
  updateCollapse();
};

useRouter().afterEach(() => updateCollapse());
onMounted(() => reloadCollapses());
onUpdated(() => reloadCollapses());

let id = 0;
class MenuItem {
  public parent?: MenuItem;
  public readonly children: MenuItem[] = [];
  public readonly permissions: string[] = [];
  public readonly id = `collapse${id++}`;
  public allowed = false;

  constructor(
    private readonly title: string,
    public readonly icon?: string,
    public readonly link?: string,
    public permission?: string
  ) {
  }

  public withPermission(permission: string): MenuItem {
    this.permissions.push(permission)
    return this
  }

  public add(item: MenuItem): MenuItem {
    this.children.push(item)
    item.parent = this
    return this
  }

  private reloadChildrenPermissions() {
    for (let c of this.children) {
      c.reloadPermissions();
    }
  }

  public reloadPermissions() {
    if (this.permissions.length === 0) {
      this.reloadChildrenPermissions();
      this.allowed = true;
      return;
    }
    if (userData.value === undefined) {
      this.allowed = false;
      return;
    }
    const permissions = userData.value.permList;

    for (let p of this.permissions) {
      if (permissions.includes(p)) {
        this.reloadChildrenPermissions();
        this.allowed = true;
        return;
      }
    }
    this.allowed = false;
  }

  private getInternalTitle(): string {
    return this.parent !== undefined ? `${this.parent.getInternalTitle()}.${this.title}` : this.title;
  }

  public getTitle(): string {
    let curr = this.children.length > 0 ? `${this.title}.name` : this.title;
    if (this.parent !== undefined) {
      return `${this.parent.getInternalTitle()}.${curr}`;
    }
    return curr;
  }
}


const menuList = reactive([
  new MenuItem('empire').withPermission('empire.see')
    .add(new MenuItem('cocpit', 'pe-7s-calculator').withPermission('cocpit.see')
      .add(new MenuItem('stats', 'pe-7s-calculator', '/'))
    )
    .add(new MenuItem('orderList', 'pe-7s-albums').withPermission('orderList.see')
      .add(new MenuItem('allOrders', 'pe-7s-albums', '/orders/all'))
      .add(new MenuItem("refundList", "pe-7s-albums", "/orders/refundList"))
    )
    .add(new MenuItem('customers', 'pe-7s-study').withPermission('customers.see')
      .add(new MenuItem('threads', 'pe-7s-study', '/customers/all-threads'))
    )
    .add(new MenuItem('productList', 'pe-7s-plugin').withPermission('products.manage')
      .add(new MenuItem('list', 'pe-7s-plugin', '/products/list'))
      .add(new MenuItem('optionMaps', 'pe-7s-plugin', '/products/optionMaps'))
      .add(new MenuItem('opinions', 'pe-7s-plugin', '/products/opinions/list'))
    )
    .add(new MenuItem('websites', 'pe-7s-notebook').withPermission('website.manage')
      .add(new MenuItem('list', 'pe-7s-notebook', '/websites/list'))
      .add(new MenuItem('categories', 'pe-7s-notebook', '/websites/category/list'))
    )
    .add(new MenuItem('scanner', 'pe-7s-browser')
      .add(new MenuItem('list', 'pe-7s-browser', '/scanner/list'))
      .add(new MenuItem('emulation', 'pe-7s-browser', '/scanner/emulation'))
    )
  ,
  new MenuItem('shipping').withPermission('empireshipping.see')
    .add(new MenuItem('carriers', 'pe-7s-car', '/shipping/carriers'))
    .add(new MenuItem('empireshipping', 'pe-7s-expand1').withPermission('empireshipping.see')
      .add(new MenuItem('map', 'pe-7s-expand1', '/shipping/empire/shippingMap').withPermission('empireshipping.map.see'))
      .add(new MenuItem('routes', 'pe-7s-expand1', '/delivery/routes'))
      .add(new MenuItem('vehicles', 'pe-7s-expand1', '/delivery/vehicles'))
      .add(new MenuItem('drivers', 'pe-7s-expand1', '/shipping/empire/drivers'))
    )
  ,
  new MenuItem('supplier').withPermission('supplier.see')
    .add(new MenuItem('suppliers', 'pe-7s-wallet', '/supplier/list').withPermission('supplier.seelist'))
    .add(new MenuItem('packages', 'pe-7s-box2', '/supplier/packages'))
    .add(new MenuItem('products', 'pe-7s-plugin')
      .add(new MenuItem('productList', 'pe-7s-plugin', '/supplier/product/list').withPermission('supplier.productlist.seeall'))
      .add(new MenuItem('orderList', 'pe-7s-plugin', '/supplier/product/orderList').withPermission('supplier.orderlist.see'))
      .add(new MenuItem('series', 'pe-7s-plugin', '/supplier/product/bulkOrderList').withPermission('supplier.series.see'))
    )
    .add(new MenuItem('components', 'pe-7s-photo-gallery')
      .add(new MenuItem('categories', 'pe-7s-photo-gallery', '/production/components/categories'))
      .add(new MenuItem('list', 'pe-7s-photo-gallery', '/production/components/all'))
      .add(new MenuItem('productList', 'pe-7s-photo-gallery', '/supplier/component/productList').withPermission('supplier.components.productlist.seeall'))
      .add(new MenuItem('demandList', 'pe-7s-photo-gallery', '/supplier/component/demandList').withPermission('supplier.orderlist.see'))
      .add(new MenuItem('orderList', 'pe-7s-photo-gallery', '/supplier/component/orderList').withPermission('supplier.orderlist.see').withPermission('supplier.components.orderlist.see'))
      .add(new MenuItem('series', 'pe-7s-photo-gallery', '/supplier/component/bulkOrderList').withPermission('supplier.series.see'))
    )
    .add(new MenuItem('materials', 'pe-7s-plugin')
      .add(new MenuItem('list', 'pe-7s-plugin', '/supplier/materials/list'))
    )
  ,
  new MenuItem('manufacturing').withPermission('manufacturing.see')
    .add(new MenuItem('processList', 'pe-7s-tools', '/manufacturing/components/processList'))
    .add(new MenuItem('components', 'pe-7s-diamond', '/manufacturing/components/manufacturingComponentList'))
    .add(new MenuItem('sessions', 'pe-7s-calculator', '/manufacturing/components/sessionList'))
    .add(new MenuItem('workstations', 'pe-7s-gym', '/manufacturing/components/workstationList'))
    .add(new MenuItem('platformList', 'pe-7s-map-2', '/manufacturing/components/platformList'))
    .add(new MenuItem('itemList', 'pe-7s-albums', '/manufacturing/components/itemList'))
    .add(new MenuItem('operators', 'pe-7s-users', '/manufacturing/operators/list'))
    .add(new MenuItem('costs', 'pe-7s-cash', '/manufacturing/components/costs/list'))
  ,
  new MenuItem('warehouse').withPermission('warehouse.see')
    .add(new MenuItem('reception', 'pe-7s-camera')
      .add(new MenuItem('packages', 'pe-7s-camera', '/supplier/reception/packages'))
      .add(new MenuItem('supplierComponents', 'pe-7s-camera', '/supplier/reception/supplierComponents'))
      .add(new MenuItem('manufacturingComponents', 'pe-7s-camera', '/supplier/reception/manufacturingComponents'))
    )
    .add(new MenuItem('sectors', 'pe-7s-drawer', '/warehouse/sectors'))
    .add(new MenuItem('packages', 'pe-7s-box2', '/warehouse/packages')
      .add(new MenuItem("stocktaking", 'pe-7s-box2', '/warehouse/packages/stocktaking')))
    .add(new MenuItem('components', 'pe-7s-plugin')
      .add(new MenuItem('list', 'pe-7s-plugin', '/warehouse/components/list'))
      .add(new MenuItem('productPicking', 'pe-7s-plugin', '/warehouse/components/productpicking'))
      .add(new MenuItem('futureProductList', 'pe-7s-plugin', '/warehouse/components/futureproductlist'))
      .add(new MenuItem('binComponentList', 'pe-7s-plugin', '/warehouse/components/bincomponentlist'))
    )
    .add(new MenuItem("materials", "pe-7s-plugin")
      .add(new MenuItem("list", "pe-7s-plugin", "/warehouse/materials/list"))
    )
  ,
  new MenuItem('workers').withPermission('workers.see')
    .add(new MenuItem('roles', 'pe-7s-user', '/workers/roles'))
    .add(new MenuItem('competences', 'pe-7s-users')
      .add(new MenuItem('list', 'pe-7s-users', '/workers/competenceList'))
      .add(new MenuItem('matrix', 'pe-7s-users', '/workers/competenceMatrixList'))
    )
    .add(new MenuItem('list', 'pe-7s-users', '/workers/all'))
  ,
  new MenuItem('bookkeeping').withPermission('bookkeeping.see')
    .add(new MenuItem('accounting', 'pe-7s-notebook', '/bookkeeping/accounting')
      .add(new MenuItem('journal', 'pe-7s-albums', '/bookkeeping/journal'))
      .add(new MenuItem('accounts', 'pe-7s-cash', '/bookkeeping/accounts'))
      .add(new MenuItem('journalSchemas', 'pe-7s-magic-wand', '/bookkeeping/journalSchemas'))
    )
    .add(new MenuItem("bankAccounts", "pe-7s-notebook", "/bookkeeping/bankAccounts"))
    .add(new MenuItem('evidences', 'pe-7s-notebook')
      .add(new MenuItem('invoiceVat', 'pe-7s-notebook', '/bookkeeping/evidence/invoiceVat'))
      .add(new MenuItem('bill', 'pe-7s-notebook', '/bookkeeping/evidence/bill'))
      .add(new MenuItem('balanceTransfer', 'pe-7s-notebook', '/bookkeeping/evidence/balanceTransfer'))
      .add(new MenuItem('inventory', 'pe-7s-notebook', '/bookkeeping/evidence/inventory'))
      .add(new MenuItem('labor', 'pe-7s-notebook', '/bookkeeping/evidence/labor'))
      .add(new MenuItem('payments', 'pe-7s-notebook', '/bookkeeping/evidence/payment'))
      .add(new MenuItem('massPayments', 'pe-7s-notebook', '/bookkeeping/evidence/massPayment'))
      .add(new MenuItem('accountingOrder', 'pe-7s-notebook', '/bookkeeping/evidence/accountingOrder'))
    )
    .add(new MenuItem("transactions", "pe-7s-notebook")
      .add(new MenuItem("bank", "pe-7s-notebook", "/bookkeeping/transactions/bank"))
      .add(new MenuItem("cash", "pe-7s-notebook", "/bookkeeping/transactions/cash"))
    )
    .add(new MenuItem("declarations", "pe-7s-notebook")
      .add(new MenuItem("pl", "pe-7s-notebook", "/bookkeeping/declarations/pl"))
    )
    .add(new MenuItem('sourceDocuments', 'pe-7s-notebook', '/bookkeeping/sourceDocuments'))
    .add(new MenuItem('contractors', 'pe-7s-notebook', '/bookkeeping/contractors'))
]);

for (const menu of menuList) {
  menu.reloadPermissions();
}
watch(userData, () => {
  for (const menu of menuList) {
    menu.reloadPermissions();
  }
});

</script>
<style lang="scss">
.sidebar {
  background-color: rgba(255, 255, 255, .1);
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, .1), inset 0 0.125em 0.5em rgba(0, 0, 0, .15);
  overflow-y: auto;
  height: 100%;

  .btn-toggle,
  .sub-link {
    padding-left: .5rem;
    font-weight: 600;
    color: var(--bs-body-color);
    background-color: transparent;
  }

  .btn-toggle:hover,
  .main-link:hover,
  .btn-toggle-nav a:hover {
    color: rgba(var(--bs-body-color), .85);
    background-color: var(--bs-tertiary-bg);
  }

  .btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
  }

  .btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
  }

  .btn-toggle-nav a {
    padding-left: 2.75rem !important;
    margin-top: .125rem;
    width: 100%;
  }

  button,
  a {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1.5rem;
  }

  .main-link {
    padding-left: 1.75rem;
    font-weight: 600;
  }
}

[data-bs-theme="dark"] .sidebar .btn-toggle::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}
</style>

<template>
  <div class="flex-shrink-0 p-3 sidebar offcanvas-lg offcanvas-start bg-body-tertiary" id="sidebarMenu">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="sidebarMenuLabel">pietrowe.pl</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu"
        aria-label="Close"></button>
    </div>
    <div class="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
      <ul class="list-unstyled ps-0">
        <EndpointEditor />
        <template v-for="item in menuList" :key="item.id">
          <li class="pb-3 mb-3 text-decoration-none border-bottom" v-if="item.allowed">
            <div class="fs-5 fw-semibold">{{ t(`sidebar.${item.getTitle()}`) }}</div>
            <ul class="list-unstyled ps-0">
              <template v-for="item2 in item.children" :key="item2.id">
                <li class="mb-1" v-if="item2.allowed">
                  <button class="w-100 btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
                    data-bs-toggle="collapse" :data-bs-target="`#${item2.id}`" aria-expanded="false"
                    v-if="item2.children.length > 0">
                    <i :class="`${item2.icon} me-2`" v-if="item2.icon !== undefined"></i>
                    {{ t(`sidebar.${item2.getTitle()}`) }}
                  </button>
                  <RouterLink :to="item2.link" :class="{ 'text-bg-primary': $route.path === item2.link }"
                    class="w-100 main-link btn d-inline-flex text-decoration-none align-items-center rounded border-0"
                    v-if="item2.link !== undefined && item2.children.length == 0">
                    <i :class="`${item2.icon} me-2`" v-if="item2.icon !== undefined"></i>
                    {{ t(`sidebar.${item2.getTitle()}`) }}
                  </RouterLink>
                  <div class="collapse" :id="item2.id" v-if="item2.children.length > 0">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1">
                      <template v-for="item3 in item2.children" :key="item3.id">
                        <li v-if="item3.allowed">
                          <RouterLink v-if="item3.link !== undefined" :to="item3.link"
                            :class="{ 'text-bg-primary': $route.path === item3.link }"
                            class="d-inline-flex sub-link text-decoration-none rounded ps-4">{{ t(`sidebar.${item3.getTitle()}`) }}
                          </RouterLink>
                        </li>
                      </template>
                    </ul>
                  </div>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
