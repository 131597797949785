import { Packet } from "../Packet";
export class S52DataScanner extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 52;
    }
}
(function (S52DataScanner) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 0] = "GET_ALL";
        Action[Action["TEST_CODE"] = 1] = "TEST_CODE";
        Action[Action["SCAN"] = 2] = "SCAN";
        Action[Action["GET_ONE_RICH"] = 3] = "GET_ONE_RICH";
        Action[Action["RENAME"] = 4] = "RENAME";
        Action[Action["GET_BY_HW"] = 5] = "GET_BY_HW";
    })(Action = S52DataScanner.Action || (S52DataScanner.Action = {}));
    ;
})(S52DataScanner || (S52DataScanner = {}));
